import React, { FC, useState } from "react";
import { useStore } from "@tanstack/react-store";
import { useQuery } from "@tanstack/react-query";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button as FluentButton,
  Input,
  Label,
} from "@fluentui/react-components";
import {
  Autocomplete,
  TextField,
  Skeleton,
  Typography,
  Stack,
  Chip,
  Button,
  IconButton,
  Paper,
  Tooltip,
  InputBase,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowSyncCircleRegular } from "@fluentui/react-icons";
import {
  AssetSchema,
  AssetTypeSchema,
  ParameterSchema,
  ParameterTypeDataTypeEnum,
  ParametersParameterTypeSchema,
} from "@ddb/parameter-service";
import { TreeViewBaseItem } from "@mui/x-tree-view";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { SavedQuery, isAsset, isAssetType, isSavedQuery } from "../../shared/types";
import { STORE, saveQueries } from "../store";
import { writeValuesToCells, checkValuesInCells } from "../excel";
import { CurrentQuery as CurrentQueryComponent } from "./CurrentQuery";
import { ProjectSchema } from "@ddb/environment-context-service";
import { setActionButton } from "./BottomBar";
import {
  assetsRequest,
  queryRequest,
  assetHierarchysRequest,
  tagsRequest,
  assetTypesRequest,
  parametersTypesRequest,
} from "../../shared/requests";
import { assetPathString } from "../../shared/helpers";

interface ProjectOrAssetTypeNameCellProps {
  value?: AssetTypeSchema | "project";
}

const ProjectOrAssetTypeNameCell: React.FC<ProjectOrAssetTypeNameCellProps> = ({ value }) => {
  if (!value) return <></>;
  return <>{isAssetType(value) ? value.name : "project"}</>;
};

interface ProjectOrAssetNameCellProps {
  value?: AssetSchema | ProjectSchema;
}

const ProjectOrAssetNameCell: React.FC<ProjectOrAssetNameCellProps> = ({ value }) => {
  if (!value) return <></>;
  return <>{isAsset(value) ? value.name : value?.short_title || ""}</>;
};

interface ParametersValueAndUnitSymbolCellProps {
  parameter?: ParameterSchema;
}

const ParametersValueAndUnitSymbolCell: FC<ParametersValueAndUnitSymbolCellProps> = ({ parameter }) => {
  const value = parameter?.selected_entry?.values[0].value || "";
  const unit_symbol = parameter?.selected_entry?.values[0].unit?.symbol || "";
  const string = `${value} ${unit_symbol}`;
  return <>{string}</>;
};

type ParameterSortComparator = (v1?: ParameterSchema | null, v2?: ParameterSchema | null) => number;

function sortComparatorForParameterType(parameterType: ParametersParameterTypeSchema): ParameterSortComparator {
  switch (parameterType.data_type) {
    case ParameterTypeDataTypeEnum.String:
      return (v1, v2) => {
        const v1_value = v1?.selected_entry?.values[0].value || "";
        const v2_value = v2?.selected_entry?.values[0].value || "";
        return v1_value.localeCompare(v2_value);
      };
    case ParameterTypeDataTypeEnum.Float:
    case ParameterTypeDataTypeEnum.Integer:
    case ParameterTypeDataTypeEnum.Number:
      return (v1, v2) => {
        const v1_value = Number(v1?.selected_entry?.values[0].value || 0);
        const v2_value = Number(v2?.selected_entry?.values[0].value || 0);
        return v1_value - v2_value;
      };
    case ParameterTypeDataTypeEnum.Date:
      return (v1, v2) => {
        const v1_value = new Date(v1?.selected_entry?.values[0].value || "");
        const v2_value = new Date(v2?.selected_entry?.values[0].value || "");
        return v1_value.getTime() - v2_value.getTime();
      };
    case ParameterTypeDataTypeEnum.Boolean:
      return (v1, v2) => {
        const v1_value = v1?.selected_entry?.values[0].value === "true";
        const v2_value = v2?.selected_entry?.values[0].value === "true";
        return v1_value === v2_value ? 0 : v1_value ? 1 : -1;
      };
    default:
      return () => 0;
  }
}

interface AssetFilterProps {
  items: TreeViewBaseItem[];
  selectedItems: TreeViewBaseItem["id"][] | undefined;
  handleSelectedItemChange: (event: React.SyntheticEvent, ids: TreeViewBaseItem["id"][]) => void;
  valueString: string;
  placeholder: string;
  ariaLabel: string;
  loading?: boolean;
}

const TreeViewFilter: FC<AssetFilterProps> = ({
  items,
  selectedItems,
  handleSelectedItemChange,
  valueString,
  placeholder,
  ariaLabel,
  loading,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Paper variant="outlined" sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <InputBase
        sx={{ flex: 1, m: 4 }}
        inputProps={{ "aria-label": ariaLabel, loading }}
        value={selectedItems && selectedItems.length > 0 ? `${selectedItems.length} ${valueString} selected` : ""}
        placeholder={placeholder}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      />
      <Dialog open={open}>
        <DialogTrigger disableButtonEnhancement>
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
            disabled={loading}
            onClick={() => setOpen(true)}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </DialogTrigger>
        <DialogSurface>
          <RichTreeView
            multiSelect
            checkboxSelection
            items={items}
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemChange}
            sx={{
              width: "100%",
              height: "calc(80vh)",
              overflowY: "auto",
              marginBottom: 4,
            }}
          />
          <DialogActions>
            <FluentButton color="secondary" onClick={() => setOpen(false)}>
              Close
            </FluentButton>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    </Paper>
  );
};

function findParent(items: TreeViewBaseItem[], parent: AssetSchema["parent"]): TreeViewBaseItem | undefined {
  for (const item of items) {
    if (item.id === parent) {
      return item;
    }
    if (item.children) {
      const found = findParent(item.children, parent);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}

const SelectParameters: FC = () => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const currentQuery = useStore(STORE, ({ currentQuery }) => currentQuery);
  const projectTags = useQuery({
    queryKey: ["projectTags", currentQuery.project_id],
    queryFn: tagsRequest({ tagScope: [(currentQuery.project_id || "") as unknown as object] }),
  });
  const assetTypes = useQuery({
    queryKey: ["assetTypes", currentQuery.project_id],
    queryFn: assetTypesRequest({ projectId: [currentQuery.project_id || ""] }),
  });
  const parameterTypes = useQuery({
    queryKey: ["parameterTypes", currentQuery.project_id],
    queryFn: parametersTypesRequest({ projectId: [currentQuery.project_id || ""] }),
  });
  const assets = useQuery({
    queryKey: ["allAssets", currentQuery.project_id],
    queryFn: async () => {
      const assets = await assetsRequest({ projectId: [currentQuery.project_id || ""] })();
      const items: TreeViewBaseItem[] = [];
      const assetCopy = new Map(assets);
      let assetIndex = 0;
      while (assetCopy.size > 0) {
        const assetToAdd = Array.from(assetCopy.values())[assetIndex];
        const parent = findParent(items, assetToAdd.parent);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push({
            id: assetToAdd.id,
            label: assetToAdd.name,
            children: [],
          });
          assetCopy.delete(assetToAdd.id);
        } else {
          if (assetToAdd.parent === undefined || assetToAdd.parent === null || assetToAdd.parent === "") {
            items.push({
              id: assetToAdd.id,
              label: assetToAdd.name,
              children: [],
            });
            assetCopy.delete(assetToAdd.id);
          }
        }
        assetIndex++;
        if (assetIndex >= assetCopy.size) {
          assetIndex = 0;
        }
      }
      return items;
    },
  });
  const query = useQuery({
    refetchInterval: 120000, // 2 minutes
    queryKey: ["currentQuery", JSON.stringify(currentQuery)],
    queryFn: queryRequest(currentQuery),
  });

  async function handleSaveQueryClick(ev: React.FormEvent): Promise<void> {
    ev.preventDefault();
    const formData = new FormData(ev.target as HTMLFormElement);
    const name = formData.get("name") as string;
    if (isSavedQuery(currentQuery)) {
      const query: SavedQuery = { ...currentQuery, name };
      STORE.setState((state) => {
        const queries = state.queries.map((q) => (q.id === query.id ? query : q));
        return { ...state, queries };
      });
      await saveQueries();
    } else {
      const query: SavedQuery = {
        ...currentQuery,
        name,
        project_id: currentQuery.project_id!,
        id: crypto.randomUUID(),
      };
      STORE.setState((state) => ({
        ...state,
        queries: [...state.queries, query],
      }));
      await saveQueries();
    }
    setOpen(false);
  }

  async function handleInsertClick(): Promise<void> {
    const project = query.data?.project;
    const assetArray = Array.from(query.data?.assets.values() || []);
    const paramArray = Array.from(query.data?.params.values() || []);
    const checkedCells: boolean = await checkValuesInCells(assetArray, paramArray);
    if (!checkedCells) {
      setErrorMessage("Some cells would be overwritten by this command");
      setIsDialogOpen(true);
    } else {
      if (!project) return;
      await writeValuesToCells([project, ...assetArray], paramArray);
    }
  }

  setActionButton(
    <Stack direction="row" spacing={4} justifyContent="flex-end">
      <IconButton color="primary" onClick={() => query.refetch()}>
        <ArrowSyncCircleRegular color="primary" />
      </IconButton>
      <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
        <DialogTrigger disableButtonEnhancement>
          <Button>{isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}</Button>
        </DialogTrigger>
        <DialogSurface aria-describedby={undefined}>
          <form onSubmit={handleSaveQueryClick}>
            <DialogBody>
              <DialogTitle>{isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}</DialogTitle>
              <DialogContent>
                <Stack direction="column" spacing={4}>
                  <Label required htmlFor={"name-input"}>
                    Query Name
                  </Label>
                  <Input required id={"name-input"} name={"name"} />
                </Stack>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <FluentButton color="secondary">Close</FluentButton>
                </DialogTrigger>
                <FluentButton type="submit" color="primary">
                  {isSavedQuery(currentQuery) ? "Update Query" : "Save Query"}
                </FluentButton>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={4} sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
      <CurrentQueryComponent />

      <Autocomplete
        multiple
        limitTags={2}
        options={Array.from(assetTypes.data?.values() || [])}
        loading={assetTypes.isLoading}
        getOptionLabel={(option) => option.name}
        defaultValue={Array.from(
          currentQuery.filters?.asset_type_ids?.map((id) => assetTypes.data?.get(id) || { id, name: id }) || []
        )}
        renderInput={(params) => (
          <TextField {...params} label="Filter by asset type" placeholder="Filter by asset type" />
        )}
        onChange={(_, value) => {
          STORE.setState((state) => ({
            ...state,
            currentQuery: {
              ...state.currentQuery,
              filters: {
                ...state.currentQuery.filters,
                asset_type_ids: value.map((v) => v.id),
              },
            },
          }));
        }}
      />

      <TreeViewFilter
        items={assets.data || []}
        selectedItems={currentQuery.filters?.asset_ids || []}
        handleSelectedItemChange={(_, ids) => {
          STORE.setState((state) => ({
            ...state,
            currentQuery: {
              ...state.currentQuery,
              filters: {
                ...state.currentQuery.filters,
                asset_ids: ids,
              },
            },
          }));
        }}
        placeholder="Select Assets"
        ariaLabel="Select Assets"
        valueString="Assets"
        loading={assets.isLoading}
      />

      <Autocomplete
        multiple
        limitTags={2}
        options={Array.from(projectTags.data?.values() || [])}
        loading={projectTags.isLoading}
        getOptionLabel={(option) => option.name}
        defaultValue={Array.from(
          currentQuery.filters?.tag_ids?.map((id) => projectTags.data?.get(id) || { id, name: id }) || []
        )}
        renderInput={(params) => <TextField {...params} label="Filter by tag" placeholder="Filter by tag" />}
        onChange={(_, value) => {
          STORE.setState((state) => ({
            ...state,
            currentQuery: {
              ...state.currentQuery,
              filters: {
                ...state.currentQuery.filters,
                tag_ids: value.map((v) => v.id),
              },
            },
          }));
        }}
      />

      <Autocomplete
        multiple
        limitTags={2}
        options={Array.from(parameterTypes.data?.values() || [])}
        loading={parameterTypes.isLoading}
        getOptionLabel={(option) => option.name}
        defaultValue={[]}
        renderInput={(params) => (
          <TextField {...params} label="Filter by parameter type" placeholder="Filter by parameter type" />
        )}
        onChange={(_, value) => {
          STORE.setState((state) => ({
            ...state,
            currentQuery: {
              ...state.currentQuery,
              filters: {
                ...state.currentQuery.filters,
                parameter_type_ids: value.map((v) => v.id),
              },
            },
          }));
        }}
      />

      <Stack direction="row" spacing={4}>
        {query.isLoading && <Skeleton variant="text" width={100} />}
        {query.isError && <Typography color="error">Failed to load parameters</Typography>}
        {query.isSuccess && <Chip label={`${query.data?.assets.size || 0} assets`} />}
        {query.isSuccess && <Chip label={`${query.data?.params.size || 0} parameters`} />}
        {query.isSuccess && (
          <Button variant="contained" onClick={handleInsertClick}>
            Insert
          </Button>
        )}
      </Stack>
      {query.isPending && <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%" }} />}
      {!query.isPending && (
        <>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <DataGrid
              disableColumnFilter
              density="compact"
              autosizeOptions={{ includeOutliers: true, includeHeaders: false }}
              loading={query.isLoading}
              sx={{ width: "100%", height: "100%" }}
              rows={[
                {
                  id: query.data?.project.project_id || "new",
                  parent: query.data?.project,
                  children: Array.from(query.data?.params.values() || []).filter((param) => param.parents.length === 0),
                },
                ...Array.from(query.data?.assets?.values() || []).map((asset) => ({
                  id: asset.id,
                  parent: asset,
                  children: Array.from(query.data?.params.values() || []).filter((param) =>
                    param.parents.some((parent) => parent.id === asset.id)
                  ),
                })),
              ]}
              columnGroupingModel={[
                {
                  groupId: "parent",
                  headerName: "Parent Asset",
                  children: [{ field: "parent.asse_type.name" }, { field: "parent.name" }],
                },
                {
                  groupId: "children",
                  headerName: "Parameters",
                  children: query.data?.param_types.map(({ id }) => ({ field: `child.${id}.value` })) || [],
                },
              ]}
              columns={[
                {
                  field: "parent.asse_type.name",
                  headerName: "Type",
                  valueGetter: (_, row) => row.parent.asset_type,
                  renderCell: (params) => (
                    <ProjectOrAssetTypeNameCell value={(params.value as AssetTypeSchema) || "project"} />
                  ),
                },
                {
                  field: "parent.name",
                  headerName: "Name",
                  valueGetter: (_, row) => row.parent,
                  renderCell: (params) => {
                    const id = params.value?.id || params.value?.project_id || "";
                    const assetHierarchys = useQuery({
                      queryKey: ["asset-hierarchy-refs", id],
                      queryFn: assetHierarchysRequest(id),
                      refetchOnWindowFocus: false,
                      enabled: true,
                    });
                    const hierarchies = assetHierarchys.data?.get(id) || [];
                    const assetPath = params.value ? assetPathString(hierarchies, " > ", query.data?.project) : "";
                    return (
                      <Tooltip title={assetPath}>
                        <span className="table-cell-trucate">
                          <ProjectOrAssetNameCell value={params.value as AssetSchema | ProjectSchema} />
                        </span>
                      </Tooltip>
                    );
                  },
                },
                ...(query.data?.param_types || []).map((param_type) => ({
                  field: `child.${param_type.id}.value`,
                  headerName: param_type.name,
                  sortComparator: sortComparatorForParameterType(param_type),
                  valueGetter: (_: any, row: any) =>
                    row.children.find(({ parameter_type }) => parameter_type.id === param_type.id),
                  renderCell: (params: any) => {
                    const id = params.value?.parents.at(0)?.id || "";
                    const assetHierarchys = useQuery({
                      queryKey: ["asset-hierarchy-refs", id],
                      queryFn: assetHierarchysRequest(id),
                      refetchOnWindowFocus: false,
                      enabled: true,
                    });
                    const hierarchies = assetHierarchys.data?.get(id) || [];
                    const assetPath = params.value ? assetPathString(hierarchies, " > ", query.data?.project) : "";
                    return (
                      <Tooltip title={assetPath}>
                        <span className="table-cell-trucate">
                          <ParametersValueAndUnitSymbolCell parameter={params.value as ParameterSchema} />
                        </span>
                      </Tooltip>
                    );
                  },
                })),
              ]}
            />
          </Paper>
        </>
      )}
      <Dialog open={isDialogOpen} modalType="alert">
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <p>{errorMessage}</p>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <FluentButton color="secondary" onClick={() => setIsDialogOpen(false)}>
                  Close
                </FluentButton>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </Stack>
  );
};

export default SelectParameters;
