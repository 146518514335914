import React from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { Stack, Typography, Card, CardActions, CardContent, Button } from "@mui/material";
import { templateService } from "../../shared/ddb";
import { ProjectSchema } from "@ddb/environment-context-service";
import { TemplateSchema } from "@ddb/template-service";
import { STORE } from "../store";
import { CurrentQuery } from "./CurrentQuery";

async function getTemplates(projectId: ProjectSchema["project_id"]): Promise<TemplateSchema[]> {
  try {
    const env = templateService();
    const res = await env.getTemplates({ projectId: [projectId] });
    return res.data.templates;
  } catch {
    throw new Error(`Failed to get templates.`);
  }
}

interface TemplateProps {
  template: TemplateSchema;
}

const Template: React.FC<TemplateProps> = ({ template }) => {
  const navigate = useNavigate();

  function handleSelectClick() {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, template_id: template.id },
    }));
    navigate("/create/dataset");
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          {template.name}
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>{template.description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSelectClick}>
          Select
        </Button>
      </CardActions>
    </Card>
  );
};

const TemplateSelect: React.FC = () => {
  const { project_id } = useStore(STORE, ({ currentQuery }) => currentQuery);
  const templateQuery = useQuery<TemplateSchema[]>({
    queryKey: ["templates", project_id],
    retry: false,
    queryFn: () => getTemplates(project_id || ""),
  });

  return (
    <Stack direction="column" spacing={1}>
      <CurrentQuery />
      <Typography variant="h4">Select Template</Typography>
      {templateQuery.data?.map((template) => <Template key={template.id} template={template} />)}
    </Stack>
  );
};

export default TemplateSelect;
