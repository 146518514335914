import React from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import { Stack, Typography, Card, CardContent, FormControlLabel, Button, Checkbox } from "@mui/material";
import { DataSetSchema } from "@ddb/parameter-service";
import { TemplateSchema } from "@ddb/template-service";
import { parameterService } from "../../shared/ddb";
import { isTemplateQuery } from "../../shared/types";
import { STORE } from "../store";
import { CurrentQuery } from "./CurrentQuery";

async function getDatasets(templateId: TemplateSchema["id"]): Promise<DataSetSchema[]> {
  try {
    const env = parameterService();
    const res = await env.getDataSets({ templateId: [templateId] });
    return res.data.data_sets;
  } catch {
    throw new Error(`Failed to get datasets for DDB.`);
  }
}

interface DatasetProps {
  dataset: DataSetSchema;
}

const Dataset: React.FC<DatasetProps> = ({ dataset }) => {
  function handlecheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const checked = e.target.checked;
    STORE.setState((state) => {
      if (!isTemplateQuery(state.currentQuery)) return state;
      const data_sets_ids = state.currentQuery.data_sets_ids || [];
      const index = data_sets_ids.findIndex((id) => id === dataset.id);
      if (checked && index === -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: [...data_sets_ids, dataset.id],
          },
        };
      } else if (!checked && index !== -1) {
        return {
          ...state,
          currentQuery: {
            ...state.currentQuery,
            data_sets_ids: data_sets_ids.filter((id) => id !== dataset.id),
          },
        };
      }
      return state;
    });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <FormControlLabel control={<Checkbox />} label={dataset.name} onChange={handlecheckboxChange} />
      </CardContent>
    </Card>
  );
};

const DatasetSelect: React.FC = () => {
  const navigate = useNavigate();
  const { dispatchToast } = useToastController("global-toaster");
  const template_id = useStore(STORE, ({ currentQuery }) =>
    isTemplateQuery(currentQuery) ? currentQuery.template_id : undefined
  );
  if (!template_id) throw new Error("Template is required for DatasetSelect.");
  const datasetQuery = useQuery<DataSetSchema[]>({
    queryKey: ["datasets", template_id],
    retry: false,
    queryFn: () =>
      getDatasets(template_id).catch((error) => {
        dispatchToast(
          <Toast>
            <ToastTitle>Error</ToastTitle>
            <ToastBody subtitle="Error">Failed to get datasets for template.</ToastBody>
          </Toast>,
          { intent: "error" }
        );
        throw error;
      }),
  });

  const datasets = datasetQuery.data || [];

  function handleNextClick(): void {
    navigate("/edit");
  }

  return (
    <Stack direction="column" spacing={1}>
      <CurrentQuery />
      <Typography variant="h4">Select a Dataset(s)</Typography>
      <Button variant="contained" onClick={handleNextClick}>
        Next
      </Button>
      {datasets.map((dataset) => (
        <Dataset key={dataset.id} dataset={dataset} />
      ))}
    </Stack>
  );
};

export default DatasetSelect;
