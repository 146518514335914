/* global Office */

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { initialize as initializeOpenTelemtry } from "../shared/opentelemetry";
import { initialize as initializePosthog } from "../shared/posthog";
import { App } from "./components/App";

const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

Office.onReady(async () => {
  initializeOpenTelemtry();
  initializePosthog();
  root?.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}
