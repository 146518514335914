import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from "@opentelemetry/core";
import { BatchSpanProcessor, SimpleSpanProcessor, ConsoleSpanExporter } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { MeterProvider, PeriodicExportingMetricReader, ConsoleMetricExporter } from "@opentelemetry/sdk-metrics";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";
import { DiagConsoleLogger, diag, metrics, trace } from "@opentelemetry/api";
import { logs } from "@opentelemetry/api-logs";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Environment, ENVIRONMENT, OTEL_RESROUCE, OTEL_DIAG_LOGGER_LEVEL } from "./config";
import {
  ApplicationInsightsTraceExporter,
  ApplicationInsightsMetricsExporter,
  ApplicationInsightsLogExporter,
} from "./appinsights";
import {
  LoggerProvider,
  SimpleLogRecordProcessor,
  BatchLogRecordProcessor,
  ConsoleLogRecordExporter,
} from "@opentelemetry/sdk-logs";

/* opentelemetry diagnostics */
export const DIAG_PROVIDER = new DiagConsoleLogger();

/* opentelemetry resource */
const RESOURCE = Resource.default().merge(OTEL_RESROUCE);

/* opentelemetry providers */
export const TRACE_PROVIDER = new WebTracerProvider({ resource: RESOURCE });
export const METER_PROVIDER = new MeterProvider({ resource: RESOURCE });
export const LOG_PROVIDER = new LoggerProvider({ resource: RESOURCE });

/* opentelemetry processors & readers */
const METIC_READER = new PeriodicExportingMetricReader({
  exporter: Environment.Local ? new ConsoleMetricExporter() : new ApplicationInsightsMetricsExporter(),
  exportIntervalMillis: 10000,
});
const TRACE_PROCESSOR =
  ENVIRONMENT === Environment.Local
    ? new SimpleSpanProcessor(new ConsoleSpanExporter())
    : new BatchSpanProcessor(new ApplicationInsightsTraceExporter());
const LOG_PROCESSOR =
  ENVIRONMENT === Environment.Local
    ? new SimpleLogRecordProcessor(new ConsoleLogRecordExporter())
    : new BatchLogRecordProcessor(new ApplicationInsightsLogExporter());

/* opentelemetry initializer */
export function initialize() {
  diag.setLogger(DIAG_PROVIDER, OTEL_DIAG_LOGGER_LEVEL);
  logs.setGlobalLoggerProvider(LOG_PROVIDER);
  metrics.setGlobalMeterProvider(METER_PROVIDER);
  trace.setGlobalTracerProvider(TRACE_PROVIDER);
  TRACE_PROVIDER.register({
    contextManager: new ZoneContextManager(),
    propagator: new CompositePropagator({ propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator()] }),
  });
  TRACE_PROVIDER.addSpanProcessor(TRACE_PROCESSOR);
  METER_PROVIDER.addMetricReader(METIC_READER);
  LOG_PROVIDER.addLogRecordProcessor(LOG_PROCESSOR);
  registerInstrumentations({
    tracerProvider: TRACE_PROVIDER,
    meterProvider: METER_PROVIDER,
    loggerProvider: LOG_PROVIDER,
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new XMLHttpRequestInstrumentation(),
      new FetchInstrumentation(),
    ],
  });
}
