import React from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { Stack, Chip, Card, CardActions, CardContent, Typography, Button } from "@mui/material";
import { makeStyles } from "@fluentui/react-components";
import { ProjectSchema } from "@ddb/environment-context-service";
import { environmentContextService } from "../../shared/ddb";
import { Query, SavedQuery as SQuery } from "../../shared/types";
import { STORE, saveQueries } from "../store";
import { query as queryFn } from "./SelectParameters";
import { DDB_ENVRIONMENT } from "../../shared/config";

async function getProject(projectId: ProjectSchema["project_id"]): Promise<ProjectSchema> {
  try {
    const env = environmentContextService();
    const project = await env.getProjectById({ projectId: projectId as unknown as object });
    return project.data.project;
  } catch {
    throw new Error(`Failed to get project data for project id ${projectId} in DDB.`);
  }
}

const useStyles = makeStyles({ queries: { display: "grid", gap: "1rem" } });

interface SavedQueryProps {
  query: SQuery & Query;
}

const SavedQuery: React.FC<SavedQueryProps> = ({ query }) => {
  const navigate = useNavigate();
  const projectQuery = useQuery<ProjectSchema>({
    retryOnMount: true,
    queryKey: ["project-data", query.project_id],
    queryFn: () => getProject(query.project_id!),
  });
  const parameterQuery = useQuery({
    refetchInterval: 120000, // 2 minutes
    queryKey: ["parameters", query.id || crypto.randomUUID()],
    retryOnMount: true,
    queryFn: queryFn(query),
  });
  const assets = parameterQuery.data?.[0] || [];
  const params = parameterQuery.data?.[1] || [];

  function handleSelectClick() {
    STORE.setState((state) => ({ ...state, currentQuery: query }));
    navigate("/edit");
  }

  async function handleDeleteClick() {
    STORE.setState((state) => {
      const newQueries = state.queries.filter((q) => q.id !== query.id);
      return { ...state, queries: newQueries };
    });
    await saveQueries();
  }

  const project = projectQuery.data;

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          {project?.short_title}
        </Typography>
        <Typography variant="h6" component="div">
          {query.name}
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>{project?.number}</Typography>
        <Stack direction="row" spacing={1}>
          <Chip label={`${assets.length} assets`} />
          <Chip label={`${params.length} parameters`} />
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSelectClick}>
          Open
        </Button>
        <Button size="small" onClick={handleDeleteClick}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

const SavedQueries: React.FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const queries = useStore(STORE, ({ queries }) => queries);
  queries.filter(({ environment }) => environment === DDB_ENVRIONMENT);

  function handleNewQueryClick() {
    navigate("/create");
  }

  return (
    <div className={styles.queries}>
      <Button variant="contained" color="primary" onClick={handleNewQueryClick}>
        New Query
      </Button>

      {queries.map((query) => (
        <SavedQuery key={query.id} query={query} />
      ))}
    </div>
  );
};

export default SavedQueries;
