import React from "react";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { environmentContextService } from "../../shared/ddb";
import { ProjectSchema } from "@ddb/environment-context-service";
import { STORE } from "../store";

async function getProject(projectId: ProjectSchema["project_id"]): Promise<ProjectSchema> {
  try {
    const env = environmentContextService();
    const project = await env.getProjectById({ projectId: projectId as unknown as object });
    return project.data.project;
  } catch {
    throw new Error(`Failed to get project data for project id ${projectId} in DDB.`);
  }
}

export const CurrentQuery: React.FC = () => {
  const { currentQuery } = useStore(STORE);
  const projectQuery = useQuery<ProjectSchema>({
    queryKey: ["project-data", currentQuery.environment, currentQuery.project_id],
    queryFn: () => getProject(currentQuery.project_id || ""),
  });

  return (
    <Stack direction="row" spacing={1} justifyContent={"end"}>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {projectQuery.data?.short_title} ({projectQuery.data?.number})
      </Typography>
    </Stack>
  );
};
