import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Stack,
  ListItemText,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { ProjectSchema } from "@ddb/environment-context-service";
import { toArray } from "../../shared/asyncIterator";
import { environmentContextService, unpaginate } from "../../shared/ddb";
import { QueryFormat, QUERY_FORMAT_DESCRIPTIONS } from "../../shared/types";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { humanizeString } from "../utils";
import { STORE } from "../store";

async function getProjects(projectNumber: string): Promise<ProjectSchema[]> {
  try {
    if (!/^\d{6}$|^\d{8}$/.test(projectNumber)) return [];
    if (projectNumber.length === 6) projectNumber = `${projectNumber}00`;
    const env = environmentContextService();
    return await toArray(
      unpaginate(
        (after) => env.getProjects({ number: [projectNumber], after }),
        (res) => res.data.projects,
        (res) => res.data.paging?.cursors?.after
      )
    );
  } catch {
    throw new Error("Failed to get project data in DDB.");
  }
}

const ProjectSelect: React.FC = () => {
  const naviate = useNavigate();
  const { project_id, format } = useStore(STORE, ({ currentQuery }) => currentQuery);
  const [projectNumber, setProjectNumber] = useState("");
  const projectSearchQuery = useQuery<ProjectSchema[]>({
    queryKey: ["project-search", projectNumber],
    queryFn: () => getProjects(projectNumber),
  });
  const projects = projectSearchQuery.data || [];

  function handleProjectSelect(_: React.ChangeEvent<{}>, project: ProjectSchema | null): void {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, project_id: project?.project_id, id: undefined },
    }));
  }

  function handleProjectNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setProjectNumber(e.target.value);

    if (e.target.value === "") {
      projectSearchQuery.refetch();
    }
  }

  function handleFormatChange(e: SelectChangeEvent<QueryFormat>): void {
    STORE.setState((state) => ({
      ...state,
      currentQuery: { ...state.currentQuery, format: e.target.value as QueryFormat },
    }));
  }

  function handleGotoProject(): void {
    if (project_id) {
      naviate(format === QueryFormat.Manual ? "/edit" : "/create/template");
    }
  }

  return (
    <Stack spacing={2}>
      <FormControl fullWidth>
        <InputLabel>Format</InputLabel>
        <Select label="Format" value={format} onChange={handleFormatChange}>
          {Object.values(QueryFormat).map((format) => (
            <MenuItem key={format} value={format}>
              <ListItemText>{humanizeString(format)}</ListItemText>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {QUERY_FORMAT_DESCRIPTIONS[format]}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        disablePortal
        options={projects}
        loading={projectSearchQuery.isLoading}
        getOptionLabel={({ number, short_title }) => `${number}: ${short_title}`}
        onChange={handleProjectSelect}
        renderInput={(params) => <TextField {...params} label="Job Number" onChange={handleProjectNumberChange} />}
      />

      <Button variant="contained" onClick={handleGotoProject} disabled={!project_id}>
        GO TO PROJECT
      </Button>
    </Stack>
  );
};

export default ProjectSelect;
